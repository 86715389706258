<template>
  <navbar></navbar>
  <crear-doc></crear-doc>
</template>

<script>
import Navbar from './components/Navbar.vue';
import CrearDoc from  './components/CrearDoc.vue'

export default {
  components: {
    Navbar,
    CrearDoc
  }
}
</script>

<style>

</style>
