<template>
    <nav class="navbar bg-dark navbar-expand-lg">
        <div class="container-fluid">
            <span class="navbar-brand mb-0 h1 text-light display-1 ">{{ titulo }}</span>
            <div class="flex-grow-1 p-2">
                <a href="/crear" class="text-light self-align-left">

                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-file-earmark-plus-fill" viewBox="0 0 16 16">
                        <path
                            d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0M9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1M8.5 7v1.5H10a.5.5 0 0 1 0 1H8.5V11a.5.5 0 0 1-1 0V9.5H6a.5.5 0 0 1 0-1h1.5V7a.5.5 0 0 1 1 0" />
                    </svg>

                    Crear Documento</a>
            </div>




            <div class="flex">
                <button type="button" class="btn btn-outline-primary" @click.prevent="iniciarSesion">Inicia sesión</button>
            </div>
        </div>

    </nav>
</template>

<!-- En el nav bar encontramos que el botón "Iniciar sesión" activa la función iniciarSesión que se encuentra en otro componente. -->

<script>
export default{
    data(){
        return{
            titulo: 'gest-docs'
        }
    }
}
</script>

<style></style>