<template>
    <div class="d-flex justify-content-center">
        <form action="" method="get" style="width: 50%">
            <h3>Crear acta fundacional</h3>
            <div class="mb-3">
                <label for="centro" class="form-label">Centro educativo</label>
                <input type="text" class="form-control" id="centro" v-model="centro">
            </div>
            <div class="mb-3">
                <label for="localidad" class="form-label">Localidad</label>
                <input type="text" class="form-control" id="localidad" v-model="localidad">
                <div class="form-text" id="localidadHelp">Si vives en un barrio o pedanía puedes usarlo</div>
            </div>
            <div class="mb-3">
                <label for="date" class="form-label">Fecha de realización de la reunión</label>
                <input type="date" class="form-control" id="date" v-model="fecha">
            </div>
            <div class="mb-3">
                <label for="startTime" class="form-label">Hora de inicio de la reunión</label>
                <input type="time" class="form-control" id="startTime" v-model="hora">
            </div>
            <div class="mb-3">
                <label for="finishTime" class="form-label">Hora de finalización de la reunión</label>
                <input type="time" class="form-control" id="finishTime" v-model="horaFin">
            </div>
            <div class="input-group mb-3">

                <button type="submit" class="btn btn-primary btn-large" @click.prevent="crearDoc"
                    @keyup.enter="crearDoc" style="width: 100%">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-send-arrow-down-fill" viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                            d="M15.854.146a.5.5 0 0 1 .11.54L13.026 8.03A4.5 4.5 0 0 0 8 12.5c0 .5 0 1.5-.773.36l-1.59-2.498L.644 7.184l-.002-.001-.41-.261a.5.5 0 0 1 .083-.886l.452-.18.001-.001L15.314.035a.5.5 0 0 1 .54.111M6.637 10.07l7.494-7.494.471-1.178-1.178.471L5.93 9.363l.338.215a.5.5 0 0 1 .154.154z" />
                        <path fill-rule="evenodd"
                            d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.354-1.646a.5.5 0 0 1-.722-.016l-1.149-1.25a.5.5 0 1 1 .737-.676l.28.305V11a.5.5 0 0 1 1 0v1.793l.396-.397a.5.5 0 0 1 .708.708z" />
                    </svg>
                    Enviar</button>
            </div>
            <div class="alert" :class="`alert-${clase}`">
                {{ msg }}
            </div>
        </form>
    </div>
</template>

<script>
export default {
    name: 'CrearDoc',
    data() {
        return {
            centro: '',
            localidad: '',
            fecha: '',
            hora: '',
            horaFin: '',
            fechas: '',
            clase: '',
            msg: ''
        }
    },
    methods: {
        // Función que lleva todo lo relacionado con la creación del Objeto y el JSON para pasar a la API.
        crearDoc() {
            var fechas = this.separarFecha();
            var datos = {
                centro: this.centro,
                localidad: this.localidad,
                hora: this.hora,
                horaFin: this.horaFin,
                mes: fechas[1],
                ano: fechas[0],
                dia: fechas[2]
            };
            datos = JSON.stringify(datos);
            console.log(datos);
            this.enviar(datos);

        },
        // Función que separa las fechas del string que genera el input
        separarFecha() {
            var fecha = this.fecha;
            var fechas = fecha.split("-")
            this.mes = fechas[1]
            this.ano = fechas[0]
            this.dia = fechas[2]
            return fechas;
        },
        // Función que envía las solicitudes a la API.
        enviar(json) {
            this.msg = '';
            this.clase = ' spinner-border text-primary';
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-type": "application/json"
                },
                body: json,
            };
            fetch('https://api.alquibla.eu.org/acta', requestOptions).then(response => response.json()).then((url) => this.mostrar(url, 'ok')).catch((error) => this.mostrar(error, 'error'));
        },
        mostrar(res, type){
            if(type == 'ok'){
                // Se a obtenido una respuesta
                console.log('OK')
                this.clase = 'success';
                this.msg = `Documento creado correctamente, tu descarga comenzará en 3 segundos`
                setTimeout(() => {window.location.href = res["url"]}, 3000)
            } else {
                console.log('MAL');
                this.clase = 'danger';
                this.msg = `Ha habido un error, por favor vuelve a intentarlo más tarde`;
            }
        }
    }
};
</script>

<style scoped></style>